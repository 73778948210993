<template>
    <div id="print-request-report" class="container-fluid p-3 matrix-wrapper">

        <table class="table shadow" v-for="provider in providers" :key="provider.provider_id">
            <thead>
                <tr>
                    <th colspan="2" class="provider-name">
                        <span>{{ provider.name }}</span> - {{ provider.seller_name }} <br> <span>{{ provider.seller_phone }}</span>
                    </th>
                    <th colspan="2" class="text-left provider-name">
                        <span>Fat. Min: <span style="color: #20CE88;font-size: 1rem;">{{ provider.minimum_billing | currency }}</span></span><br>
                        <span>Total: <span style="color: #20CE88;font-size: 1rem;">{{ total_provider(provider) | currency }}</span></span>
                    </th>
                    <th class="text-left">
                        Última Compra
                    </th>
                    <th class="text-left">
                        Menor Preço
                    </th>
                    <th class="text-left">
                        2 Menor Preço
                    </th>
                    <th class="text-left">
                        3 Menor Preço
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-for="product in provider.products">
                    <tr :key="'name-' + product.id + '-prov-' + provider.provider_id">
                        <td colspan="4" class="text-left border-bottom-0 pl-0">
                            <div class="col">
                                <p class="mb-0 pro-name" style="position:relative">
                                    {{ product.name }}
                                    <span class="brand">{{ product.brand }}</span>
                                    <i v-if="product.observations" class="fas fa-info-circle" :title="product.observations.toUpperCase()"></i>
                                </p>
                            </div>
                        </td>
                        <td rowspan="2" class="text-center">
                            <div class="column-quantity">
                                {{ product.last_purchase | currency }}
                            </div>
                        </td>
                        <td rowspan="2" class="text-center">
                            <div class="column-quantity">
                                <span :class="{ lower: product.offer_price < product.last_purchase, higher: product.offer_price > product.last_purchase, 'total-val': true }">
                                    {{ offer_at(product, 1).offer_price | currency }}
                                </span>
                                (<span>{{ offer_at(product, 1).provider_name }}</span>)
                            </div>
                        </td>
                        <td rowspan="2" class="text-center">
                            <div class="column-quantity">
                                {{ offer_at(product, 2).offer_price | currency }} (<span>{{ offer_at(product, 2).provider_name }}</span>)
                            </div>
                        </td>
                        <td rowspan="2" class="text-center">
                            <div class="column-quantity">
                                {{ offer_at(product, 3).offer_price | currency }} (<span>{{ offer_at(product, 3).provider_name }}</span>)
                            </div>
                        </td>
                    </tr>
                    <tr :key="product.pro_id">
                        <td class="text-center border-top-0 pt-0" colspan="4">
                            <div class="col d-flex p-0">
                                <p class="mb-0 info">
                                    <span class="description">Qtd:</span>
                                    <span class="d-block text-center">{{ quantity_sum(product.stores) }}</span>
                                </p>
                                <p class="mb-0 info">
                                    <span class="description">Emb:</span>
                                    <span class="d-block text-center">
                                        {{ product.purchase_unity ? product.purchase_unity : `${product.packing.split(" ")[0]} ${product.packing.split(" ")[1]}` }}
                                    </span>
                                </p>
                                <p class="mb-0 info">
                                    <span class="description">Total:</span>
                                    <span class="d-block text-center total-val">{{ total(product.stores) | currency }}</span>
                                </p>
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>
<script>

import RequestsService from '@/services/v2/requests.service'
import { elementNavigationmMixin } from '@/mixins/navigation.mixin';
import QuotationService from "@/services/QuotationService";
import NegotiationModal from '@/modals/negotiate-offer/negotiate-offer.modal'
import ProductWrapModal from '@/modals/product-wrap/product-wrap.modal'
import { mapState } from 'vuex'
import V3RequestsService from "@/services/v3/request.service"

export default {
    components: { NegotiationModal, ProductWrapModal },
    mixins: [ elementNavigationmMixin ],
    props: [ "data" ],
    data() {
        return {
            service: new QuotationService(),
            svc: new RequestsService(),
            v3_svc: new V3RequestsService(),
            products: null,
            providers: [],
            current_product: null,
            open_edit_wrap: false,
            stores: [],
            loading: false
        }
    },
    methods: {
        offer_at(product, position) {
            position = position - 1
            return product.offers && product.offers[position] ? product.offers[position] : {}
        },
        filter_product(product, store_id) {
            let result = product.stores.find(s => s.store_id == store_id)
            return result || {}
        },
        quantity_sum(stores) {
            return stores.reduce((sum, store) => { return sum + (store.requested_quantity || 0) }, 0)
        },
        total_provider(provider) {
            let result = 0;
            provider.products.forEach(p => {
                result += this.total(p.stores)
            })
            return result
        },
        total_store(provider, store_id) {
            let result = 0
            provider.products.forEach(p => {
                p.stores.forEach(pp => {
                    if(pp.store_id == store_id) {
                        let value = pp.is_generic && pp.main_product_id == null && this.user.request_type != 1 ? parseInt(pp.packing.split(' ')[1])*pp.offer_price : pp.offer_price
                        result += value*pp.requested_quantity
                    }
                })
            })
            return result
        },
        total(stores) {
            return stores.reduce((sum, store) => {
                return sum + (
                    (store.requested_quantity || 0)*
                    (store.is_generic == 0 && store.main_product_id == null && this.user.request_type != 1 ?
                        parseInt(store.packing.split(' ')[1])*store.offer_price : store.offer_price)
                    )
            }, 0)
        },
        process_available_providers() {
            this.products.forEach(pp => {
                let prov = this.providers.find(prov => prov.provider_id == pp.provider_id && pp.seller_id == prov.seller_id)
                if(prov) {
                    let pro = prov.products.find(pr => pr.id == pp.id)
                    if(pro) {
                        pro.stores.push(pp)
                    } else {
                        prov.products.push({ ...pp, stores: [ pp ] })
                    }
                } else {
                    this.providers.push({
                        provider_id: pp.provider_id,
                        seller_id: pp.seller_id,
                        seller_name: pp.seller_name,
                        seller_phone: pp.seller_phone,
                        minimum_billing: pp.minimum_billing,
                        name: pp.provider_name, products: [ { ...pp, stores: [ pp ] } ]
                    })
                }
            })
        },
        process_available_stores() {
            this.stores.forEach(store => {
                this.products.forEach(p => {
                    p.stores = []
                    if(store.loj_id == p.store_id) {
                        if(!store.products) {
                            store.products = []
                        }
                        if(store.loj_id == p.store_id) {
                            store.products.push(p)
                        }
                    }
                })
            })
        },
        sort_products() {
            this.providers.forEach(p => {
                p.products.sort((a, b) => {
                    if (a.name > b.name) return 1
                    if (b.name > a.name) return -1
                    return 0
                })
            })
        },
        sort_stores() {
            this.stores.sort((a, b) => {
				if (a.loj_numeroLoja > b.loj_numeroLoja) return 1
				if (b.loj_numeroLoja > a.loj_numeroLoja) return -1
				return 0
			})
        },
        formatLength(e){
            return String(e).substring(0,4);
        },
        format_value_amount(amount){
            amount = amount.replace(",",".");
            var regex = new RegExp("[0-9].[5]");
            return (regex.test(amount))? parseFloat(amount): parseInt(amount);
        },
        load_offers() {
            return this.v3_svc.full_general_request_report(this.$route.params.id)
            .then(response => response.data).then(data => {
                data.forEach(off => {
                    this.providers.forEach(prov => {
                        prov.products.forEach(prod => {
                            if(prod.id == off.id) {
                                if(prod.offers) {
                                    if(!prod.offers.some(p => {
                                        return p.id == off.id && p.seller_id == off.seller_id
                                    })) {
                                        prod.offers.push(off)
                                    }
                                } else {
                                    prod.offers = [ off ]
                                }
                            }
                        })
                    })
                })
                this.providers.forEach(prov => {
                    prov.products.forEach(prod => {
                        prod.offers.sort((a, b) => {
                            if (a.offer_price > b.offer_price) return 1
                            if (b.offer_price > a.offer_price) return -1
                            return 0
                        })
                    })
                })
                this.$forceUpdate()
            }).then(() => {
                Vue.nextTick(() => {
                    window.document.title = `cotacao-${this.$route.params.id}.pdf`
                    setTimeout(function() {
                        window.print()
                        window.document.title = 'Club da Cotação'
                    }, 500)
                })
            })
        }
    },
    computed: {
        ...mapState([ 'user' ])
    },
    async mounted() {
        this.stores = await this.$store.dispatch('user/fetch_stores')
        this.products = this.data
        this.sort_stores()
        this.process_available_stores()
        this.process_available_providers()
        this.sort_products()
        this.load_offers()
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_variables';

    .total-provider {
        color: $ternary-color;
    }

    .table {
        margin: 50px 0;
        &:first-child {
            margin-top: 0 !important;
        }
    }
    .provider-name {
        text-align: center;
        span {
            font-weight: bolder;
            color: $secondary-color;
        }
    }

    .pro-name {
        font-weight: bold;
        font-size: 1rem;
        .brand {
            color: $secondary-color;
        }
    }
    .info {
        width: 33%;
        margin-left: 10px;
    }

    th.store-quantity {
        line-break: anywhere;
        white-space: revert;
        cursor: pointer;
        &:hover {
            background-color: $secondary-color;
            span, i {
                color: white !important;
            }
        }
    }
    th.store-quantity {
        min-width: 130px;
    }
    input.store-quantity {
        width: 100%;
    }

    input.store-quantity-reduce {
        width: 60%;
    }

    .total {
        border-right: 1px dashed #ece9e9;
    }

    .total-val {
        white-space: nowrap;
    }

    span.description {
        // width: fit-content;
        font-size: 0.8rem;
        white-space: nowrap;
        text-transform: uppercase;
        margin-right: 4px;
        margin-left: 2px;
        display: inline-block;
        text-align: center;
        color: $gray2;
    }

    tr:hover {
        td {
            background-color: white;
        }
    }

    .quantity-loader {
        position: absolute;
        width: 25%;
        top: -47px;
        left: 83%;
        height: fit-content !important;
    }

    .lower {
        background-color: $higher-green;
        border-radius: 6px;
        color: white;
        font-weight: 600;
        opacity: 0.6;
        padding: 0.2rem;
    }

    .higher {
        color: white !important;
        border-radius: 6px;
        background-color: $lower-red;
        font-weight: 600;
        opacity: 0.6;
        padding: 0.2rem;
    }

    .fa-store-alt {
        margin-right: 4px;
    }

    .fa-info-circle {
        color: $secondary-color;
        cursor: pointer;
    }

    .cursor {
        cursor: pointer;
    }

    .description.store-total {
        text-align: center;
        color: $ternary-color;
    }

    .obs {
        color: $secondary-color;
    }

    i.fa-handshake-o {
        width: 10px;
        padding-left: 4px;
        color: $orange;
        cursor: pointer;
    }

    .fa-edit {
        margin-left: 10px;
        color: $primary-color;
        cursor: pointer;
    }

    .matrix-wrapper {
        overflow-x: scroll;
    }

    .pro-col {
        min-width: 600px;
    }

    .column-quantity {
        display: flex;
        justify-content: right;
        align-items: center;
        span {
            margin-right: 4px;
            color: $secondary-color;
        }
    }
    .column-stock{
        width: 45px;
        margin-left: 4px;
        display: flex;
        align-items: center;
    }

</style>
