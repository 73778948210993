<template>
    <div>
        <div class="preorder-seller-container" v-for="(seller, index) in visibleSellers()" v-bind:key="index">
            <div class="preorder-seller-title">
                <div class="preorder-seller-title-text">
                    <div>
                        <span v-if="seller.isExpand" v-on:click="() => {seller.isExpand = false; $forceUpdate()}" class="material-icons-outlined preorder-seller-expanded-icon">expand_more</span>
                        <span v-else v-on:click="() => {seller.isExpand = true; $forceUpdate()}" class="material-icons-outlined preorder-seller-retracted-icon">expand_more</span>
                    </div>
                    <div>
                        <div>Empresa: {{seller.loj_nomeFantasia}}</div>
                        <div>CNPJ: {{ `${parse_cnpj(seller.loj_cnpj)}` || "" | VMask('##.###.###/####-##')}}</div>
                    </div>
                </div>
                <div class="preorder-seller-title-buttons">
                    <div class="preorder-seller-title-button-total">Total: {{ calculate_total(seller) | currency }}</div>
                    <div class="preorder-seller-title-button-excel" @click="generate_pdf(seller.cotacao_id, seller)">
                        <div v-show="seller.is_loading_pdf">
                            <img style="height: 3.3vh" src="/images/loader.svg" alt="loading">
                        </div>
                        <div style="display: flex; align-items: center;" v-show="!seller.is_loading_pdf">
                            <span class="material-icons-outlined preorder-seller-title-button-pdf-icon">picture_as_pdf</span>
                            Exportar PDF
                        </div>
                    </div>
                    <div class="preorder-seller-title-button-excel" @click="export_store_report(seller)">
                        <div v-show="seller.is_loading_excel">
                            <img style="height: 3.3vh" src="/images/loader.svg" alt="loading">
                        </div>
                        <div style="display: flex; align-items: center;" v-show="!seller.is_loading_excel">
                            <img class="preorder-seller-title-button-excel-icon" src="@/assets/excel.svg" alt="excel">
                            Exportar Excel
                        </div>
                    </div>
                    <div style="margin-right: 2vw" v-if="type == 'ORDERED' && has_vf_integration" v-on:click="sync_many_orders(seller)" class="preorder-seller-title-button-sendorder">
                        <span style="margin-right: 0.5vw" class="material-icons-outlined">autorenew</span>
                        Enviar Pedidos VF
                    </div>
                    <div v-if="type == 'ORDERED' && has_sapi_integration" v-on:click="sync_many_orders_sapi(seller)" class="preorder-seller-title-button-sendorder">
                        <span style="margin-right: 0.5vw" class="material-icons-outlined">autorenew</span>
                        Enviar Pedidos
                    </div>
                </div>
            </div>
            <div v-if="seller.isExpand">
                <div class="preorder-seller-table-header">
                    <div class="preorder-seller-table-cart"></div>
                    <div class="preorder-seller-table-suplier">Fornecedor</div>
                    <div class="preorder-seller-table-date">Data Entrega</div>
                    <div class="preorder-seller-table-payment">Cond. de Pgto.</div>
                    <div class="preorder-seller-table-value">Valor</div>
                    <div class="preorder-seller-table-min">Fat. Min.</div>
                    <div class="preorder-seller-table-delivery">Tipo de Entrega</div>
                </div>
                <div :class="getSpecialBackground (idx)" class="preorder-seller-table-row" v-for="(provider, idx) in visibleProviders(seller)" v-bind:key="idx">
                    <div class="preorder-seller-table-cart">
                        <div>
                            <router-link class="preorder-seller-table-cart" :to="{ name: 'lancamento-cliente-monitores-vencedores', params: { id: $route.params.id, sellerid: provider.idVendedor, sid: seller.loj_id, providerid: provider.idFornecedor }, query: { cli_id: seller.cli_id } }">
                                <span class="material-icons-outlined preorder-seller-table-cart-icon">shopping_cart</span>
                            </router-link>
                        </div>
                        <div>
                                <span class="cart vf" v-if="has_vf_integration && type == 'ORDERED'">
                                <i class="fas fa-sync-alt"
                                    aria-hidden="true"
                                    title="Enviar VF"
                                    v-show="
                                        has_vf_integration &&
                                        (!provider.vf_integration_status ||
                                        (provider.vf_integration_status && provider.vf_integration_status != 'INTEGRATED'))
                                    "
                                    @click="sync_order(provider)"></i>
                                <i class="fas fa-exclamation-triangle"
                                    style="color: red !important"
                                    aria-hidden="true"
                                    :title="provider.vf_integration_obs"
                                    v-show="
                                        has_vf_integration &&
                                        provider.vf_integration_obs &&
                                        provider.vf_integration_status &&
                                        provider.vf_integration_status != 'INTEGRATED'
                                    "
                                    @click="edit_product(provider, seller); showNewProductModal = true"></i>
                                <i class="fas fa-check-square"
                                    aria-hidden="true"
                                    :title="'Sincronizado #' + provider.external_reference"
                                    v-show="
                                        has_vf_integration &&
                                        provider.vf_integration_status &&
                                        provider.vf_integration_status == 'INTEGRATED'
                                    "></i>
                            </span>

                            <span class="cart vf" v-if="has_sapi_integration && type == 'ORDERED'">
                                <i class="fas fa-sync-alt"
                                    aria-hidden="true"
                                    title="Enviar Pedido"
                                    v-show="
                                        has_sapi_integration &&
                                        (!provider.sapi_integration_status ||
                                        (provider.sapi_integration_status && provider.sapi_integration_status != 'INTEGRATED'))
                                    "
                                    @click="sync_order_sapi(provider)"></i>

                                <i class="fas fa-exclamation-triangle"
                                    style="color: red !important"
                                    aria-hidden="true"
                                    :title="provider.sapi_integration_obs"
                                    v-show="
                                        has_sapi_integration &&
                                        provider.sapi_integration_obs &&
                                        provider.sapi_integration_status &&
                                        provider.sapi_integration_status != 'INTEGRATED'
                                    "
                                    ></i>

                                <i class="fas fa-check-square"
                                    aria-hidden="true"
                                    :title="'Sincronizado #' + provider.sapi_external_reference"
                                    v-show="
                                        has_sapi_integration &&
                                        provider.sapi_integration_status &&
                                        provider.sapi_integration_status == 'INTEGRATED'
                                    "></i>
                            </span>
                        </div>
                    </div>
                    <div class="preorder-seller-table-suplier preorder-seller-table-row-mobile">
                        <div class="preorder-seller-table-suplier-mobile-header">Fornecedor</div>
                        {{provider.nomeFornecedor}}
                    </div>
                    <div class="preorder-seller-table-date preorder-seller-table-row-mobile">
                        <div class="preorder-seller-table-suplier-mobile-header">Data Entrega</div>
                        {{ provider.entrega ? provider.entrega.data_entrega : '' | formatDateOnly }}
                    </div>
                    <div class="preorder-seller-table-payment preorder-seller-table-row-mobile">
                        <div class="preorder-seller-table-suplier-mobile-header">Cond. de Pgto.</div>
                        {{ provider.entrega ? provider.entrega.forma : '' }}
                    </div>
                    <div class="preorder-seller-table-value preorder-seller-table-row-mobile">
                        <div class="preorder-seller-table-suplier-mobile-header">Valor</div>
                        <div :style="getValueStyle(provider)">{{ provider.valorTotal | currency }}</div>
                    </div>
                    <div class="preorder-seller-table-min preorder-seller-table-row-mobile">
                        <div class="preorder-seller-table-suplier-mobile-header">Fat. Min.</div>
                        {{ provider.entrega ? parseFloat(provider.entrega.faturamento_minimo) : '' | currency }}
                    </div>
                    <div class="preorder-seller-table-delivery preorder-seller-table-row-mobile">
                        <div class="preorder-seller-table-suplier-mobile-header">Tipo de Entrega</div>
                        {{ provider.tpc_nome }}
                    </div>
                </div>
            </div>
        </div>
        <new-product-modal v-if="product && showNewProductModal"
                           :closeDialog="closeNewProductModal"
                           :product="product"
                           :categories="categories"
                           :internal_code_only="true"
                           @reload_products="reload"
                           @close="product = null" />
        <a id="download-pdf" style="display:none;"></a>
		<a id="download-excel" style="display:none;"></a>
    </div>
</template>


<script>
import QuotationService from "@/services/QuotationService";
import RequestService from "@/services/v3/request.service";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import Button from "@/components/ui/buttons/button.component"
import NoDataAvailable from "@/components/utils/no-data.utils"
import { perm_mixin } from '@/mixins/permission.mixin'
import VarejoFacilService from '@/services/VarejoFacilService';
import SapiService from '@/services/SapiService';
import StorageService from '@/services/local-storage.service';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import ProductService from '@/services/v2/products.service';
import NewProductModal from "@/modals/new-product/new-product.modal";
import { mapState } from 'vuex'

	export default {
        mixins: [ perm_mixin, loaderMixin ],
		components: {
            'new-product-modal': NewProductModal,
			ccButton: Button,
			'no-data-available': NoDataAvailable
		},
		props: ['type', 'sellers', 'wraps', 'is_loading', 'loadPreOrders', 'loadTotalOrders'],
		data() {
			return {
                showNewProductModal: true,
                svc: new RequestService(),
				productService: new QuotationService(),
                vf_svc: new VarejoFacilService(),
                sapi_svc: new SapiService(),
                prod_svc: new ProductService(),
                storage_svc : new StorageService(),
				collapsed: [],
				is_loading_pdf: false,
                loading_orders: false,
                has_vf_integration: false,
                has_sapi_integration: false,
                orders_completed: 0,
                current_seller: null,
                categories: [],
                product: null,
			};
		},
        computed: {
            ...mapState([ 'user' ])
        },
		methods: {
            getValueStyle(provider){
                if (provider.entrega && provider.entrega.faturamento_minimo > provider.valorTotal) return 'background-color: lightcoral; padding: 2px 1vw; width: fit-content; border-radius: 8px; color: white'
            },
            getSpecialBackground (idx) {
                if (idx % 2 != 0) return 'page-table-line-special'
            },
            closeNewProductModal: function() {
              this.showNewProductModal = false;
            },
            reload() {
                this.loadPreOrders().then(() => {
                    let curr = this.sellers.find(s => s.loj_id == this.current_seller.loj_id)
                    if(curr) {
                        curr.expanded = true
                    }
                    this.$forceUpdate()
                });
            },
            edit_product(seller, current) {
                if(seller.edi_children) {
                    let di = seller.edi_children.find(d => d.status == 'FAILED')
                    if(di) {
                        this.prod_svc.load(di.data_id).then(response => {
                            this.product = response.data
                            this.current_seller = current
                        })
                    }
                }
            },
            can_sync_all_vf(seller) {
                return this.visibleProviders(seller).some(p =>
                    !p.vf_integration_status ||
                    (p.vf_integration_status && p.vf_integration_status != 'INTEGRATED')
                )
            },
            can_sync_all_sapi(seller) {
                return this.visibleProviders(seller).some(p =>
                    !p.sapi_integration_status ||
                    (p.sapi_integration_status && p.sapi_integration_status != 'INTEGRATED')
                )
            },
            parse_cnpj: function(val) {
                return val && `${val}`.length < 14 ? `0${val}` : val
            },
			calculate_total(seller) {
				let result = 0;
				this.visibleProviders(seller).forEach(p => result += p.valorTotal)
				return result;
			},
			ordered_providers(seller) {
				return seller.fornecedores.filter(p => p.possuiPedido == 1);
			},
			unordered_providers(seller) {
				return seller.fornecedores.filter(p => p.possuiPedido == 0);
			},
			expandSeller(seller) {
				this.$set(seller, 'expanded', !seller.expanded);
			},
			order(seller) {
                this.current_seller = seller
				this.$swal.fire({
					title: 'Confirma realização do pedido?',
					text: "Seu pedido será enviado ao fornecedor!",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Sim, realizar pedido!'
				}).then(async (result) => {
					if (result.value) {

                        const visible_sellers = this.visibleProviders(seller)

                        const haserror = false;
                        this.loading_orders = true;
                        for(let i = 0; i < visible_sellers.length; i++) {

                            let p = visible_sellers[i]

                            let data = {
								idcotacao: this.$route.params.id,
								loja: seller.loj_id,
								idvendedor: p.idVendedor
							};

							let response = await this.productService.order(data)

                            if(response.status == 500) {
                                this.$swal.fire(
                                    'Erro!',
                                    'Ocorreu um erro ao realizar um dos pedidos.',
                                    'error'
                                );
                                haserror = true;
                            } else {
                                this.orders_completed += 1;
                            }
						}
                        if(!haserror) {
                            this.$swal.fire(
                                'Recarregando informações!',
                                'Pedidos realizados com sucesso.',
                                'success'
                            );
                        }
                        this.loading_orders = false;
                        this.loadTotalOrders();
                        this.loadPreOrders();
					}
				});
			},
			cancel(seller) {
				this.$swal.fire({
					title: 'Confirma cancelamento do pedido?',
					text: "Seu pedido será cancelado!",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Sim, cancalar pedido!'
				}).then((result) => {
					if (result.value) {
						var providers = this.ordered_providers(seller);

						this.visibleProviders(seller).forEach(async (p, i) => {
							let data = {
								numeroCotacao: this.$route.params.id,
								loja: seller.loj_id,
								vendedor: p.idVendedor
							};


							await this.productService.cancel_order(data)
							.then(response => response.data)
							.then(data => {
								if(i+1 == providers.length) {
									this.$swal.fire(
										'Recarregando informações!',
										'Seu pedido foi cancelado com sucesso.',
										'success'
									);
									this.is_loading = false;
									this.loadTotalOrders();
									this.loadPreOrders();
								}
							}, err => {
								if(i+1 == providers.length) {
									this.$swal.fire(
										'Erro!',
										'Ocorreu um erro ao cancelar o pedido.',
										'error'
									);
								}
							})
						})
					}
				});
			},
            sync_order(item) {
                this.present_loader("Enviando pedido...")
                this.vf_svc.export(item.id_pedido).then(response => response.data).then(data => {
                    this.dismiss_loader()
                    const notification = {
                        type: 'success',
                        message: "Pedido enviado com sucesso!"
                    }
                    this.$store.dispatch('notification/add', notification)
                    this.reload();
                }).catch(e => {
                    console.log(e.response.data.message);
                    this.dismiss_loader()
                    if(e.response.data.message) {
                        const notification = {
                            type: 'error',
                            message: e.response.data.message
                        }
                        this.$store.dispatch('notification/add', notification)
                    }
                    this.reload()
                })
            },
            sync_order_sapi(item) {
                this.present_loader("Enviando pedido...")
                this.sapi_svc.export(item.id_pedido).then(response => response.data).then(data => {
                    this.dismiss_loader()
                    const notification = {
                        type: 'success',
                        message: "Pedido enviado com sucesso!"
                    }
                    this.$store.dispatch('notification/add', notification)
                    this.reload();
                }).catch(e => {
                    this.dismiss_loader()
                    if(e.response.data.message) {
                        const notification = {
                            type: 'error',
                            message: "Não foi possível sincronizar o pedido!"
                        }
                        this.$store.dispatch('notification/add', notification)
                    }
                    this.reload()
                })
            },
            sync_many_orders(seller) {

                let ids = this.visibleProviders(seller).filter(p =>
                    !p.vf_integration_status ||
                    (p.vf_integration_status && p.vf_integration_status != 'INTEGRATED')
                ).map(p => p.id_pedido)
                if(ids) {
                    this.present_loader("Enviando pedidos...")
                    this.vf_svc.export_many(ids).then(response => response.data).then(data => {
                        this.dismiss_loader()
                        this.present_info("Solicitação enviada com sucesso, seus pedidos serão processados em background, verifique novamente em algums minutos!")
                        this.loadPreOrders();
                    }).catch(e => {
                        console.log(e.response.data.message);
                        this.dismiss_loader()
                        if(e.response.data.message) {
                            const notification = {
                                type: 'error',
                                message: e.response.data.message
                            }
                            this.$store.dispatch('notification/add', notification)
                        }
                        this.reload();
                    })
                } else {
                    this.present_loader("Nenhum pedido dispon'i'vel para integração")
                }
            },
            sync_many_orders_sapi(seller) {

                let ids = this.visibleProviders(seller).filter(p =>
                    !p.sapi_integration_status ||
                    (p.sapi_integration_status && p.sapi_integration_status != 'INTEGRATED')
                ).map(p => p.id_pedido)
                if(ids) {
                    this.present_loader("Enviando pedidos...")
                    this.sapi_svc.export_many(ids).then(response => response.data).then(data => {
                        this.dismiss_loader()
                        this.present_info("Solicitação enviada com sucesso, seus pedidos serão processados em background, verifique novamente em algums minutos!")
                        this.loadPreOrders();
                    }).catch(e => {
                        console.log(e.response.data.message);
                        this.dismiss_loader()
                        if(e.response.data.message) {
                            const notification = {
                                type: 'error',
                                message: e.response.data.message
                            }
                            this.$store.dispatch('notification/add', notification)
                        }
                        this.reload();
                    })
                } else {
                    this.present_loader("Nenhum pedido dispon'i'vel para integração")
                }
            },
			visibleSellers() {
				return this.sellers
                    .filter(s => this.can('REQUEST', 'READ_ALL_SUBREQUESTS') || s.loj_id == this.user.store_id)
                    .filter(s => s.visible && this.visibleProviders(s).length > 0)
                    .sort((s1, s2) => s1.loj_numeroLoja - s2.loj_numeroLoja);
			},
			visibleProviders(seller) {
                let result = []

				result = result.concat(seller.fornecedores.filter(s => this.isVisible(s) && s.entrega).sort((a, b) => {
					let f1 = a.nomeFornecedor
					let f2 = b.nomeFornecedor
					if (f1 < f2) return 1
					if (f2 > f1) return -1
					return 0
                }))

                result = result.concat(seller.fornecedores.filter(s => this.isVisible(s) && !s.entrega));

				return result
			},
			isVisible(provider) {
				if(provider.possuiPedido == 1 && this.type == "ORDERED" || provider.possuiPedido == 0 && this.type == "NOT_ORDERED") {
					return true;
				} else {
					return false;
				}
			},
			export_store_report(seller) {
				this.$set(seller, 'is_loading_excel', true);
				this.svc.export_store_report(this.$route.params.id, seller.loj_id)
				.then(response => response.data)
				.then(data => {
					var file = new Blob([data], {type: 'application/vnd.ms-excel'});
					var fileURL = URL.createObjectURL(file);
					var el = document.getElementById("download-excel");
					el.download = "Conferencia-" + seller.loj_nomeFantasia + "-" + this.$route.params.id + ".xls";
					el.href = fileURL;
					el.click();
					setTimeout(function(){
						window.URL.revokeObjectURL(fileURL);
					}, 100);
					this.$set(seller, 'is_loading_excel', false);
				})
				.catch(error => {
					this.$set(seller, 'is_loading_excel', false);
					ErrorHandlerService.handle(error, this.$store);
				});
			},
			generate_pdf(numeroSolicitacao, seller) {
				this.$set(seller, 'is_loading_pdf', true);
				this.productService
				.generate_pdf(this.$route.params.id, numeroSolicitacao)
				.then(response => response.data)
				.then(data => {
					var file = new Blob([data], {type: 'application/pdf'});
					var fileURL = URL.createObjectURL(file);
					var el = document.getElementById("download-pdf");
					el.download = "Conferencia-" + seller.loj_nomeFantasia + "-" + this.$route.params.id + ".pdf";
					el.href = fileURL;
					el.click();
					setTimeout(function(){
						window.URL.revokeObjectURL(fileURL);
					}, 100);
					this.$set(seller, 'is_loading_pdf', false);
				})
				.catch(error => {
					this.$set(seller, 'is_loading_pdf', false);
					ErrorHandlerService.handle(error, this.$store);
				});
			},
			print(){
				self.print();
			},
			toggle_collapse(index) {
				this.$set(this.collapsed, index, !this.collapsed[index]);
			},
			processCollapse() {
				if(this.type == "NOT_ORDERED") {
					this.sellers.forEach(s => s.expanded = false)
				} else {
					this.sellers.forEach(s => s.expanded = true)
				}
			},
			formatPrice(value) {
				let val = (value/1).toFixed(2).replace('.', ',')
				return '' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
			},
            check_vf_integration() {
                let has_vf_integration = StorageService.get_string('has_vf_integration')
                if(has_vf_integration) {
                    this.has_vf_integration = !!has_vf_integration;
                }
            },
            check_order_integration(){
                let settings = JSON.parse(StorageService.get_string('user_settings'));
                if(settings){
                    let external_user = settings.find(s => s.key == 'EXTERNAL_USER');
                    let external_pass = settings.find(s => s.key == 'EXTERNAL_PASSWORD');
                    let external_domain = settings.find(s => s.key == 'EXTERNAL_SUBDOMAIN');
                    if(external_user && external_pass && external_domain){
                        this.has_sapi_integration = true;
                    }
                }
            },
		},
        mounted() {
            this.check_vf_integration()
            this.check_order_integration()
        }

	}
</script>

<style scoped>
.preorder-seller-container{
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 1vh 1vw;
    font-size: 1vw;
    margin-bottom: 4vh;
}
.preorder-seller-title{
    display: flex;
    justify-content: space-between;
}
.preorder-seller-title-text{
    font-weight: 400;
    font-size: 1.3em;
    color: #606060;
    display: flex;
    max-width: 26%;
    text-transform: uppercase;
}
.preorder-seller-title-buttons{
    display: flex;
    align-items: center;
}
.preorder-seller-title-button-total{
    background: rgba(23, 162, 184, 0.1);
    border: 1px solid #E5E5E5;
    border-radius: 9.5px;
    font-weight: 600;
    font-size: 1.3em;
    color: #5281FF;
    display: flex;
    align-items: center;
    padding: 2vh 1vw;
    margin-right: 2vw;
}
.preorder-seller-table-header{
    display: flex;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    padding: 1vh 0;
    margin: 3vh 0;
    font-weight: 600;
    font-size: 1.19em;
    color: #505050;
}
.preorder-seller-table-cart{
    min-width: 6%;
    display: flex;
}
.preorder-seller-table-suplier{flex: 1}
.preorder-seller-table-date{width: 15%}
.preorder-seller-table-payment{width: 12%}
.preorder-seller-table-value{width: 12%}
.preorder-seller-table-min{width: 12%}
.preorder-seller-table-delivery{width: 12%}
.preorder-seller-table-row{
    display: flex;
    font-weight: 300;
    font-size: 1.1em;
    color: #605F5F;
    padding: 1vh 0;
}
.preorder-seller-table-cart-icon{
    color: var(--primary-color);
    cursor: pointer;
    font-size: 1.5em;
    margin-left: 1vw;
}
.preorder-seller-retracted-icon{
    color: #FE6700;
    transform: rotate(-90deg);
    font-size: 2.5em;
    cursor: pointer;
}
.preorder-seller-expanded-icon{
    color: #FE6700;
    font-size: 2.5em;
    cursor: pointer;
}
.preorder-seller-title-button-sendorder{
    display: flex;
    align-items: center;
    border: 1px solid #E5E5E5;
    border-radius: 9.5px;
    padding: 2vh 1vw;
    font-weight: 600;
    font-size: 1.3em;
    color: #5281FF;
    cursor: pointer;
}
.preorder-seller-title-button-excel{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E5E5E5;
    border-radius: 9.5px;
    padding: 2vh 1vw;
    font-weight: 600;
    font-size: 1.3em;
    cursor: pointer;
    margin-right: 2vw;
    min-width: 14vw;
}
.preorder-seller-title-button-excel-icon{
    margin-right: 1vw;
}
.preorder-seller-title-button-pdf-icon{
    color: #EF443B;
    margin-right: 1vw;
}
.preorder-seller-table-send-icon{
    cursor: pointer;
    color: #4267B2;
    margin-left: 1vw;
}
.page-table-line-special{  background: #F7F7F7; }
.preorder-seller-table-suplier-mobile-header{display: none;}
@media only screen and (max-width: 1000px){.preorder-seller-container{font-size: 1.5vw;}}
@media only screen and (max-width: 700px){
    .preorder-seller-container{font-size: 2vw; padding: 3vh 4vw;}
    .preorder-seller-title{flex-direction: column;}
    .preorder-seller-title-text{
        width: 100%; 
        max-width: none;
        margin-bottom: 3vh;
    }
    .preorder-seller-title-button-excel{
        margin-right: 0;
        width: 30%;
    }
    .preorder-seller-title-button-total{
        width: 30%;
        margin-right: 0;
        justify-content: center;
    }
    .preorder-seller-title-buttons{justify-content: space-between;}
    .preorder-seller-table-header{
        display: none;
    }
    .preorder-seller-table-row{
        margin-top: 3vh;
        flex-direction: column;
        gap: 1vh;
    }
    .preorder-seller-table-suplier-mobile-header{
        display: flex;
        font-weight: bold;
        font-size: 1.2em;
    }
    .preorder-seller-table-row-mobile{
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 1.5em;
    }
}




@media only screen and (max-width: 540px){.preorder-seller-container{font-size: 2.5vw;}}
@media only screen and (max-width: 420px){
    .preorder-seller-container{font-size: 3vw;}
    .preorder-seller-title-buttons{
        flex-direction: column; 
        gap: 2vh;
    }
    .preorder-seller-title-button-excel{
        width: 100%;
    }
    .preorder-seller-title-button-total{
        width: 100%;
    }
}
</style>
