<template>
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="infoprice-modal-header">
                Código: {{productGtin}}
                <span v-on:click="close()" class="material-icons infoprice-modal-header-close">cancel</span>
            </div>
            <cc-loader v-if="isLoading" style="max-height: unset; margin-top: 20vh;"/>
            <div v-else>
                <div v-if="!productNotFound" class="infoprice-modal-body">
                    <div class="infoprice-modal-body-title">{{product.descricao}}</div>
                    <div class="infoprice-modal-body-table">
                        <div class="infoprice-modal-body-image-containear">
                            <img class="infoprice-modal-body-image" :src="product.img" alt="">
                        </div>
                        <div class="infoprice-modal-body-info-container">
                            <div class="infoprice-modal-body-info-line">
                                <div>GTIN:</div>
                                <div>{{productGtin}}</div>
                            </div>
                            <div class="infoprice-modal-body-info-line">
                                <div>Seção:</div>
                                <div>{{product.section}}</div>
                            </div>
                            <div class="infoprice-modal-body-info-line">
                                <div>Categoria: </div>
                                <div>{{product.category}}</div>
                            </div>
                            <div class="infoprice-modal-body-info-price-line">
                                <div>Preço de compra: </div>
                                <div class="infoprice-modal-body-info-price-line-value">{{buyPrice | currency}}</div>
                            </div>
                            <div class="infoprice-modal-body-info-subtitle">Inf. preço de venda </div>
                            <div class="infoprice-modal-body-info-line2">
                                <div>Nº lojas</div>
                                <div class="infoprice-modal-body-info-store-numbers">{{product.cnpjs_distintos}}</div>
                            </div>
                            <div style="background: #F7F7F7;" class="infoprice-modal-body-info-line2">
                                <div>Preço mínimo</div>
                                <div style="background: rgba(48, 170, 76, 0.5);" class="infoprice-modal-body-info-store-value">{{product.preco_minimo.toFixed(2)}}</div>
                            </div>
                            <div  class="infoprice-modal-body-info-line2">
                                <div>Média de preço mínimo</div>
                                <div style="color: #505050; outline: 1px solid #505050;" class="infoprice-modal-body-info-store-value">{{product.preco_quartil_inferior.toFixed(2)}}</div>
                            </div>
                            <div style="background: #F7F7F7;" class="infoprice-modal-body-info-line2">
                                <div>Preço médio</div>
                                <div style="background: #C8A439;" class="infoprice-modal-body-info-store-value">{{product.preco_medio.toFixed(2)}}</div>
                            </div>
                            <div  class="infoprice-modal-body-info-line2">
                                <div>Média de preço máximo</div>
                                <div style="color: #505050; outline: 1px solid #505050;" class="infoprice-modal-body-info-store-value">{{product.preco_quartil_superior.toFixed(2)}}</div>
                            </div>
                            <div style="background: #F7F7F7;" class="infoprice-modal-body-info-line2">
                                <div>Preço máximo</div>
                                <div style="background: rgba(205, 42, 42, 0.5);" class="infoprice-modal-body-info-store-value">{{product.preco_maximo.toFixed(2)}}</div>
                            </div>
                            <div class="infoprice-modal-body-info-button-line">
                                <StandardButton class="infoprice-modal-body-info-button" :action="close" :text="'Voltar'"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="infoprice-modal-notfound">
                    <span class="material-icons-outlined" style="font-size: 2em;">error</span>
                    Desculpe, produto não encontrado.
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import StandardButton from "@/components/ui/buttons/StandardButton.vue";

    export default {
        name: 'InfoPriceProductModal',
        props: ['close', 'productGtin', 'apiService', 'buyPrice'],
        components:{StandardButton},
        data(){
            return {
                isLoading: true,
                product: undefined,
                productNotFound: false
            }
        },
        created() {
            this.apiService.getProduct({value: this.productGtin, states: [this.user.stores[0].state.fu]}).then(response => {
                if (response.info1 && response.info2) {
                    this.product = response.info1
                    this.product.img = response.info2.foto
                    this.product.section = response.info2.secao
                    this.product.category = response.info2.categoria
                    this.isLoading = false
                } else {
                    this.productNotFound = true
                    this.isLoading = false
                }
                
            })
        },
        computed: {
            ...mapState([ 'user' ])
        }
    }
</script>

<style scoped>
    .modal-mask {
        position: fixed;
        z-index: 4;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: opacity 0.3s ease;
        overflow-y: auto;
        font-size: 1vw;
    }
    .modal-content-container {
        background-color: #fff;
        border-radius: 12px;
        width: 65vw;
        min-height: 90vh;
    }
    .infoprice-modal-header{
        background: var(--primary-color);
        border-radius: 10px 10px 0px 0px;
        font-weight: 500;
        font-size: 2.3em;
        color: #FFFFFF;
        padding: 1vh 2vw;
        display: flex;
        justify-content: space-between;
    }
    .infoprice-modal-header-close{
        font-size: 1.4em;
        cursor: pointer;
    }
    .infoprice-modal-body{ padding: 1vh 1vw; }
    .infoprice-modal-body-title{
        font-weight: 400;
        font-size: 2em;
        color: #303030;
        margin-top: 2vh;
    }
    .infoprice-modal-body-table{display: flex;}
    .infoprice-modal-body-image-containear{
        width: 40%;
        height: 65vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .infoprice-modal-body-image{
        max-height: 100%; 
        max-width: 100%;
    }
    .infoprice-modal-body-info-container{
        flex: 1;
        padding-right: 2vw;
    }
    .infoprice-modal-body-info-line{
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: 1.2em;
        color: #707070;
        margin-top: 2vh;
    }
    .infoprice-modal-body-info-price-line{
        font-weight: 600;
        font-size: 1.4em;
        color: #303030;
        display: flex;
        margin-top: 4.5vh;
    }
    .infoprice-modal-body-info-price-line-value{
        font-weight: 600;
        color: #0677C9;
        background: rgba(23, 162, 184, 0.1);
        margin-left: 5vw;
    }
    .infoprice-modal-body-info-subtitle{
        margin-top: 2vh;
        font-weight: 400;
        font-size: 1.4em;
        color: #707070;
    }
    .infoprice-modal-body-info-line2{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        font-size: 1.1em;
        color: #909090;
        margin-top: 1vh;
        padding: 0.5vh 0;
    }
    .infoprice-modal-body-info-store-numbers{
        font-weight: 300;
        font-size: 1.8em;
        text-decoration-line: underline;
        color: var(--primary-color);
    }
    .infoprice-modal-body-info-store-value{
        padding: 0.5vh 1vw;
        border-radius: 3px;
        color: white;
    }
    .infoprice-modal-body-info-button-line{
        display: flex;
        justify-content: center;
        margin-top: 3vh;
        margin-bottom: 5vh;
    }
    .infoprice-modal-body-info-button{
        width: 60%;
    }
    .infoprice-modal-notfound{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20vh;
        font-size: 3.5em;
        text-align: center;
        color: lightgray;
    }
    @media only screen and (max-width: 1000px) {
        .modal-mask{font-size: 1.5vw;}
    }
    @media only screen and (max-width: 700px) {
        .modal-mask{
            font-size: 1.7vw;
            align-items: flex-start;
            padding: 10vh 0;
        }
        .infoprice-modal-body-table{flex-direction: column;}
        .infoprice-modal-body-image-containear{
            width: 100%;
            height: 50vh;
        }
        .modal-content-container{width: 95vw;}
    }
    @media only screen and (max-width: 500px) {
        .modal-mask{font-size: 2.2vw;}
        .infoprice-modal-body-info-container{font-size: 3vw;}
    }
</style>