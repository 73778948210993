<template>
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="new-product__header-container">
              <span class="new-product__header-title">
                Editar Marca
              </span>
              <span @click="close()" class="material-icons new-product__closebtn-icon">
                cancel
              </span>
            </div>
            <div class="products-page__inputs-container">
              <div class="products-page__inputs-wrapper">
                <div class="products-page__inputs-title-wrapper">
                  <span class="products-page__inputs-title">Marca</span>
                </div>
                <input v-model="newBrandName" class="products-page__inputs" placeholder="Digite a marca" type="text">
              </div>
           </div>
           <div class="action-btns__wrapper">
              <div style="margin-left: 1em;">
                <StandardButton :text="'Salvar'" :action="save"/>
              </div>
              <div>
                <StandardButton :text="'Cancelar'" :action="close"/>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import * as CONSTANTS from '@/constants/constants';
import StandardButton from '@/components/ui/buttons/StandardButton.vue';

export default {
    props: {
      brandId: {
        type: Number,
      },
    },
    emits: ['reload'],
    components: {
        StandardButton,
    },
    data() {
        return {
            newBrandName: '',
        };
    },
    methods: {
        save() {
          this.updateBrandName()
        },
        updateBrandName() {
          const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          }
          const params = {
            pro_id: this.brandId,
            pro_observacao: this.newBrandName,
          }
        axios
          .put(`${CONSTANTS.API_V3_URL}/admin/management/products/updateV2`, params, config)
          .then(data => {
            this.$swal.fire('Configurações Salvas')
            this.close()
            this.$emit('reload')
          })
          .catch(error => {
            console.log(error)
          });
        },
        close() {
          this.$emit("close");
        },
    },
    mounted() {
      console.log(this.brandId)
    },
};
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
}
.modal-content-container {
    background-color: white;
    width: 60vw;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}
.new-product__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}
.new-product__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 2.6em;
}
.new-product__closebtn-icon {
  color: #ffffff;
  font-size: 30px;
  cursor: pointer;
}
.products-page__inputs-container {
  display: flex;
  margin: 1em;
}
.products-page__inputs-wrapper {
  margin: 0em 0.8em;
}
.products-page__inputs-title {
  font-size: 1.7em;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #898989;
}
.products-page__inputs {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 0.5em;
  width: 20vw;
  height: 6vh;
  margin-top: 0.5em;
  font-size: 1.2em;
}
.products-page__inputs:focus {
  border: 1px solid #ff7110 !important;
}
.action-btns__wrapper {
  display: flex;
  flex-direction: row-reverse;
  margin: 2em;
}
</style>
