import http from "../http";
import Service from "../../Service";

/**
 * @type OrderService
 */
export default class OrderService extends Service {

	load_orders(product_id) {
		return http.get(`clients/products/${product_id}/orders`, {
            headers: this.getHeaders()
        });
	}

}
