<template>
	<div>
		<!-- <div class="cc-loader" :class="{ hidden : !is_loading }">
			<img src="/images/loader.svg"/>
			<span>Carregando</span>
		</div>

		<a id="download-pdf" style="display:none;"></a>

		<no-data-available v-if="!is_loading && sellers && sellers.length == 0" :text="type == 'NOT_ORDERED' ? 'Sem Pedidos Pendentes' : 'Sem Pedidos Confirmados'" />
		<div class="responsive-table shadow-sm mt-3 p-2" v-if="sellers && sellers.length > 0" v-show="!is_loading">

			<table id="winners-pre-order-summary" class="table winners-pre-order-summary m-0">

				<thead>
					<tr>
						<th class="company">
							<div>
								<span class="empresa-titulo pt-2">
                                    <i class="fas fa-store-alt" style="color: #6081e8;margin-right: 10px;font-size: 1rem;"></i>
                                    Empresa: {{ user.group }}
                                </span>
							</div>
						</th>
						<th style="padding: 0 12.8px !important;min-width: 13rem;max-width: 13rem;">
							<label class="total empresa-titulo " style="padding-top:20px !important"><span style="color: white !important;">Total: {{ calculate_total(store) | currency }}</span></label>
						</th>
						<th v-if="type == 'ORDERED'">
							<cc-button
								:classes="{ 'hidden' : store.is_loading_pdf }"
								:content="'Exportar PDF'"
								:icon="'fa fa-file-pdf'"
								@click="generate_pdf(store.cotacao_id, store)">
							</cc-button>
							<button class="generate-pdf" :class="{ 'hidden' : !store.is_loading_pdf}">
								<span v-bind:key="'generate-pdf-loader'">
									<img src="/images/loader.svg">
									Carregando
								</span>
							</button>
						</th>
					</tr>
				</thead>

    		</table>

    		<table id="winner-pre-order-details" class="table winner-pre-order-details mt-2" v-if="(store.expanded && type == 'ORDERED') || type == 'NOT_ORDERED'">
				<thead class="gray">
					<tr>
						<th class="pl-4">Fornecedor</th>
						<th>Data Entrega</th>
						<th>Cond. de Pgto</th>
						<th>Valor</th>
						<th>Fat. Min.</th>
						<th>Tipo de Entrega</th>
						<th>Status</th>
					</tr>
      			</thead>
      			<tbody>
                    <template  v-for="provider in sellers">
                        <tr v-bind:key="provider.idFornecedor + provider.idVendedor">

                            <td>
                                <span class="toggle-products" @click="show_products(provider)" :class="{ on : provider.open_products }">
                                    <i class="fas fa-boxes" title="Produtos"></i>
                                </span>
                                <span class="cart">
                                    <router-link :to="{ name: 'request-matrix-overview', params: { id: $route.params.id, sellerid: provider.idVendedor, providerid: provider.idFornecedor } }">
                                        <i class="fa fa-shopping-cart" ></i>
                                    </router-link>{{provider.nomeFornecedor}}
                                </span>
                            </td>
                            <td>
                                {{ provider.entrega ? provider.entrega.data_entrega : '' | formatDateOnly }}
                            </td>
                            <td>
                                {{ provider.entrega ? provider.entrega.forma : '' }}
                            </td>
                            <td>
                                <span :class="{ lower: provider.entrega && provider.valorTotal < provider.entrega.faturamento_minimo}">{{ provider.valorTotal | currency }}</span>
                            </td>
                            <td>
                                {{ provider.entrega ? parseFloat(provider.entrega.faturamento_minimo) : '' | currency }}
                            </td>
                            <td>
                                {{ provider.tpc_nome }}
                            </td>
                            <td>
                                <label class="label cc-label label-success" v-if="provider.possuiPedido == 1">Pedido Gerado</label>
                                <label class="label cc-label label-warning" v-if="provider.possuiPedido == 0">Pedido Pendente</label>
                            </td>

                        </tr>
                        <tr v-if="provider.loading_products" v-bind:key="'load-' + provider.idFornecedor + provider.idVendedor">
                            <td colspan="7">
                                <cc-loader :show_txt="false" />
                            </td>
                        </tr>
                        <tr v-if="provider.open_products && !provider.loading_products && provider.products" v-bind:key="'prod-' + provider.idFornecedor + provider.idVendedor">
                            <td colspan="7" class="pp-wrapper">
                                <table class="table provider-products">
                                    <thead>
                                        <tr>
                                            <th class="pl-4">Produto</th>
                                            <th>Quantidade</th>
                                            <th>Preço</th>
                                            <th>Ultima Compra</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="prod in provider.products">
                                            <tr v-bind:key="'provider-prods-' + prod.id">
                                                <td class="pl-3">
                                                    <span class="toggle-winners"
                                                        @click="show_winners(prod)" :class="{ on : prod.open_winners }">
                                                        <i class="fas fa-users" title="Vencedores"></i>
                                                    </span>
                                                    {{ prod.name }}
                                                </td>
                                                <td>
                                                    {{ (prod.is_generic == 1 || prod.pro_principal) ? prod.quantidadeDigitacao : prod.packing.split(" ")[1] }}
                                                    {{ prod.purchase_unity ? prod.purchase_unity : prod.packing.split(" ")[0] }}
                                                </td>
                                                <td>{{ prod.offer_price | currency }}</td>
                                                <td>{{ prod.last_purchase | currency }}</td>

                                            </tr>
                                            <tr v-bind:key="'provider-prod-winners-' + prod.id" v-if="prod.open_winners">
                                                <td colspan="4" class="winners">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="pl-4">Fornecedor</th>
                                                                <th>Marca</th>
                                                                <th>Forma de Pgto</th>
                                                                <th>Qtd. Emb</th>
                                                                <th class="text-center">Vl. Unitário</th>
                                                                <th>Observações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>



                                                            <tr v-for="winner in prod.vencedores" v-bind:key="winner.id + '-' + winner.seller_id">
                                                                <td class="provider pl-3">
                                                                    <div class="check">
                                                                        <i :class="{ 'not-visible' : !winner.is_winner || winner.is_loading}"
                                                                            class="fa fa-shopping-cart show-stores"
                                                                            @click="() => open_subrequests_modal(winner, prod)"></i>
                                                                        <cc-checkbox :label="winner.provider_name"
                                                                            :disabled="winner.has_any_order"
                                                                            :title="winner.has_any_order ? 'Não é possível alterar itens em fornecedores que já possuem pedidos' : ''"
                                                                            @change="(evt) => toggle_winner(winner, winner.seller_id, evt.target.checked, prod)"
                                                                            :checked="winner.is_winner" />
                                                                    </div>
                                                                </td>
                                                                <td class="brand">{{ winner.brand }}</td>
                                                                <td class="pgto">{{ winner.payment_condition ? winner.payment_condition: '-' }}</td>
                                                                <td class="quantity">
                                                                    <input type="text" class="disabled" readonly="readonly" v-bind:value="get_quantity(winner)" >
                                                                </td>
                                                                <td class="price">
                                                                    <input type="text" class="disabled pricing" :class="{ 'winner' : winner.is_winner }" readonly="readonly" v-bind:value="winner.offer_price | currency" >
                                                                </td>
                                                                <td>
                                                                    <input class="disabled large observation" readonly="readonly" type="text" v-bind:value="winner.observations">
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </template>
      			</tbody>
    		</table>
  		</div> -->

        <div>

            <div class="cc-loader" :class="{ hidden : !is_loading }">
                <img src="/images/loader.svg"/>
                <span>Carregando</span>
            </div>

            <div v-if="!is_loading" class="bysuplier-table">
                <div class="bysuplier-itens-container" >
                    <div class="bysuplier-title-container">
                        <div class="bysuplier-title-text">Empresa: {{ user.group }}</div>
                        <div class="bysuplier-title-button">Total: {{ calculate_total(store) | currency }}</div>
                    </div>
                    <div class="bysuplier-itens-header">
                        <div class="bysuplier-name">
                            <div class="bysuplier-icons"></div>
                            Fornecedor
                        </div>
                        <div class="bysuplier-date">Data Entrega</div>
                        <div class="bysuplier-cond">Cond. de Pgto.</div>
                        <div class="bysuplier-value">Valor</div>
                        <div class="bysuplier-min">Fat. Min.</div>
                        <div class="bysuplier-delivered">Tipo de Entrega</div>
                    </div>

                    <div v-for="(provider, idx) in sellers" :key="idx">
                        <div :class="getSpecialBackground (idx)" class="bysuplier-itens-body">
                            <div class="bysuplier-name">
                                <div class="bysuplier-icons">
                                    <span class="material-icons-outlined bysuplier-expand-icon" @click="show_products(provider)" :style="getExpandStyle(provider)">expand_more</span>
                                    <router-link :to="{ name: 'request-matrix-overview', params: { id: $route.params.id, sellerid: provider.idVendedor, providerid: provider.idFornecedor } }">
                                        <span class="material-icons-outlined bysuplier-cart-icon">shopping_cart</span>
                                    </router-link>
                                </div>
                                {{provider.nomeFornecedor}}
                            </div>
                            <div class="bysuplier-date">{{ provider.entrega ? provider.entrega.data_entrega : '' | formatDateOnly }}</div>
                            <div class="bysuplier-cond">{{ provider.entrega ? provider.entrega.forma : '' }}</div>
                            <div class="bysuplier-value">{{ provider.valorTotal | currency }}</div>
                            <div class="bysuplier-min">{{ provider.entrega ? parseFloat(provider.entrega.faturamento_minimo) : '' | currency }}</div>
                            <div class="bysuplier-delivered">{{ provider.tpc_nome }}</div>
                        </div>
                    <cc-loader v-if="provider.loading_products" style="margin-top: 3vh; height: 90%;"/>
                    <div v-if="provider.open_products">
                        <div class="bysuplier-itens-header">
                            <div class="bysuplier-name">
                                <div class="bysuplier-products-icons"></div>
                                Produto
                            </div>
                            <div class="bysuplier-quantity">Quantidade</div>
                            <div class="bysuplier-price">Preço</div>
                            <div class="bysuplier-last">Ultima Compra</div>
                        </div>
                        <div v-for="(prod, idx) in provider.products" :key="idx">
                            <div class="bysuplier-product-row">
                                <div class="bysuplier-name">
                                    <div class="bysuplier-products-icons">
                                        <span class="material-icons-outlined bysuplier-expand-icon" @click="show_winners(prod)" :style="getExpandStyle(prod)">expand_more</span>
                                    </div>
                                    <div class="bysuplier-name-text" :title="prod.name">{{prod.name}}</div>
                                </div>
                                <div class="bysuplier-quantity">
                                    {{ (prod.is_generic == 1 || prod.pro_principal) ? prod.quantidadeDigitacao : prod.packing.split(" ")[1] }}
                                    {{ prod.purchase_unity ? prod.purchase_unity : prod.packing.split(" ")[0] }}
                                </div>
                                <div class="bysuplier-price">
                                    {{ prod.offer_price | currency }}
                                </div>
                                <div class="bysuplier-last">
                                    {{ prod.last_purchase | currency }}
                                </div>
                            </div>
                            <cc-loader v-if="prod.isLoading" style="margin-top: 3vh; height: 90%;"/>
                            <div v-if="prod.open_winners" >
                                <div class="bysuplier-itens-header">
                                    <div class="bysuplier-name">
                                        <div class="bysuplier-icons-products"></div>
                                        Fornecedor
                                    </div>
                                    <div class="bysuplier-brand">marca</div>
                                    <div class="bysuplier-quantity">Qtd. Emb.</div>
                                    <div class="bysuplier-payment">Forma de PGTO.</div>
                                    
                                    <div class="bysuplier-unity-price">Valor Unitário</div>
                                    <div class="bysuplier-obs">Observação</div>
                                </div>
                                <div v-for="(winner, idx) in prod.vencedores" class="bysuplier-winner-row" :class="getSpecialBackground(idx)" v-bind:key="winner.id + '-' + winner.seller_id">
                                    <div class="bysuplier-name">
                                        <div class="bysuplier-icons-products">
                                            <div style="width: 50%; text-align: center;">
                                                <span class="material-icons-outlined bysuplier-winner-icon" v-if="winner.is_winner" @click="() => open_subrequests_modal(winner, prod)">shopping_cart</span>
                                            </div>
                                            <div style="width: 25%">
                                                <span v-if="winner.is_winner" @click="() => toggle_winner(winner, winner.seller_id, false)" class="material-icons bysuplier-winner-icon">check_box</span>
                                                <span v-else-if="winner.has_any_order" title="Não é possível alterar itens em fornecedores que já possuem pedidos" class="material-icons bysuplier-winner-icon-disabled">check_box_outline_blank</span>
                                                <span v-else @click="() => toggle_winner(winner, winner.seller_id, true)" class="material-icons bysuplier-winner-icon">check_box_outline_blank</span>
                                            </div>
                                            <div v-if="winner.is_loading" style="width: 25%">
                                                <cc-loader style="height: 20px !important;" :show_txt="false"/>
                                            </div>
                                        </div>
                                        {{winner.provider_name}}
                                    </div>
                                    <div class="bysuplier-brand">{{ winner.brand }}</div>
                                    <div class="bysuplier-quantity">{{get_quantity(winner)}}</div>
                                    <div class="bysuplier-payment">{{ winner.payment_condition ? winner.payment_condition: '-' }}</div>
                                    <div class="bysuplier-unity-price">{{winner.offer_price | currency}}</div>
                                    <div class="bysuplier-obs bysupliers-text-overflow" :title="winner.observations">{{winner.observations}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    

                </div>
            </div>
            

        </div>

        <cc-product-sub-requests v-if="current_seller && show_current_seller"
            :salesman="current_seller"
            :quotationId="$route.params.id"
            :product="current_product"
            :stores_only="true"
            @close="current_seller = null;show_current_seller=false"
            @loadProviders="() => {}" :sellers="sellers" />
	</div>
</template>


<script>
import QuotationService from "@/services/QuotationService";
import RequestService from "@/services/v3/request.service";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import Button from "@/components/ui/buttons/button.component"
import NoDataAvailable from "@/components/utils/no-data.utils"
import RequestsService from "@/services/v2/requests.service"
import ProductSubRequestsModal from "@/modals/product-sub-requests/product-sub-requests.modal";
import { mapState } from 'vuex'

export default {
    components: {
        ccButton: Button,
        'cc-product-sub-requests': ProductSubRequestsModal,
        'no-data-available': NoDataAvailable
    },
    props: ['type', 'data', 'wraps', 'is_loading', 'loadPreOrders', 'loadTotalOrders'],
    data() {
        return {
            store: {},
            sellers: [],
            temp_sellers: [],
            productService: new QuotationService(),
            svc: new RequestsService(),
            v3_svc: new RequestService(),
            collapsed: [],
            current_seller: null,
            current_product: null,
            is_loading_pdf: false,
            show_current_seller: false
        };
    },
    methods: {
        getSpecialBackground (index) { if (index % 2 != 0) return 'page-table-line-special' },
        getExpandStyle(value){
            if (value.open_products) return
            else if (value.open_winners) return
            return 'transform: rotate(-90deg);'
        },
        open_subrequests_modal(seller, product) {
            this.current_seller = {
                ...seller,
                pro_id: seller.id,
                nomeVendedor: seller.seller_name,
                nomeFornecedor: seller.provider_name,
                embalagem: seller.packing,
                valor: seller.offer_price,
                idVendedor: seller.seller_id,
                cli_id: this.store ? this.store.cli_id : undefined
            }
            this.current_product = {
                ...product,
                descricaoProduto: product.name,
                prod_id: product.id,
                flag_generico: product.is_generic,
                embalagem: product.packing,
                qtd_embalagem: product.embalagem_quantity
            }
            this.show_current_seller = true
        },
        get_quantity(winner) {
            return winner.is_generic ? winner.offer_quantity : winner.packing.split(" ")[1]
        },
        toggle_winner(winner, seller_id, is_winner) {
            this.$set(winner, "is_loading", true);
            this.$forceUpdate()
            this.productService.toggleQuotationWinner(
                this.$route.params.id,
                this.loj_id,
                winner.id,
                seller_id,
                is_winner
            ).then(response => response.data).then(() => {
                this.$emit('loadPreOrders', false, this.process_sellers)
                this.$set(winner, "is_loading", false);
                this.$set(winner, "is_winner", is_winner);
                this.$forceUpdate()
            }).catch(() => {
                const notification = {
                    type: 'error',
                    message: 'Falha na requisição, tente novamente.'
                }
                this.$store.dispatch('notification/add', notification)
                this.$forceUpdate()
            })
        },
        show_winners(prod) {
            if(!prod.open_winners) {
                prod.isLoading = true
                this.$forceUpdate()
                this.load_offers(prod).then(offers => {
                    prod.open_winners = !prod.open_winners
                    this.sort_winners(offers)
                    prod.vencedores = offers
                    prod.isLoading = false
                    this.$forceUpdate()
                }).catch(() => {
                    prod.open_winners = false
                    prod.isLoading = false
                    this.$forceUpdate()
                })
            } else {
                prod.open_winners = !prod.open_winners
                this.$forceUpdate()
            }
        },
        show_products(provider) {
            if(!provider.open_products) {
                provider.loading_products = true
                this.load_provider_products(provider).then((prods) => {
                    provider.open_products = !provider.open_products
                    provider.products = prods
                    provider.loading_products = false
                    this.$forceUpdate()
                }).catch(error => {
                    provider.loading_products = false
                    ErrorHandlerService.handle(error, this.$store);
                    const notification = {
                        type: 'error',
                        message: 'Ocorreu um erro ao alterar vencedor. Tente novamente.'
                    }
                    this.$store.dispatch('notification/add', notification)
                    this.$forceUpdate()
                });
                this.$forceUpdate()
            } else {
                provider.open_products = !provider.open_products
                this.$forceUpdate()
            }
        },
        load_provider_products(provider) {

            return this.v3_svc.request_offers(
                this.$route.params.id,
                provider.idVendedor
            ).then(response => response.data).then(data => {
                for (var i = 0; i < data.length; i++) {
                    var embalagem_split = data[i].packing.split(" ");
                    var p = data[i]
                    p.embalagem_units = embalagem_split[0];
                    p.embalagem_quantity = embalagem_split[1];
                }
                return data
            })
        },
        load_offers(product) {
            return this.v3_svc.request_offers_by_product(
                this.$route.params.id,
                product.id
            ).then(response => response.data)
        },
        calculate_total(seller) {
            let result = 0;
            this.sellers.forEach(p => result += p.valorTotal)
            return result;
        },
        ordered_providers(seller) {
            return seller.fornecedores.filter(p => p.possuiPedido == 1);
        },
        unordered_providers(seller) {
            return seller.fornecedores.filter(p => p.possuiPedido == 0);
        },
        process_sellers() {
            let result = []
            let all = this.data.map(s => s.fornecedores).flat().filter(s => this.isVisible(s))
            let sells = []
            all.forEach(s => {
                var found = sells.find(ss => ss.idFornecedor == s.idFornecedor && ss.idVendedor == s.idVendedor)
                if(found) {
                    found.valorTotal += s.valorTotal
                    return
                } else {
                    found = s
                }
                sells.push(Object.assign({}, found))
            })

            result = sells.filter(s => this.isVisible(s) && s.entrega)
                .sort((a, b) => { return a.nomeFornecedor.localeCompare(b.nomeFornecedor) })

            result = result.concat(sells.filter(s => this.isVisible(s) && !s.entrega));

            if(this.sellers && this.sellers.length > 0) {
                result.forEach(re => {
                    if(!this.sellers.some(s => {
                        return s.idFornecedor == re.idFornecedor
                    })) {
                        this.sellers.push(re)
                    }
                })
                this.sellers.forEach(s => {
                    if(!result.some(re => {
                        return s.idFornecedor == re.idFornecedor
                    })) {
                        this.sellers = this.sellers.filter(ss => ss.idFornecedor != s.idFornecedor)
                    }
                })
            } else {
                this.sellers = result
            }
            this.$forceUpdate()
        },
        sort_winners(offers) {
            offers.sort((a, b) => {
                let total1 = a.offer_price
                let total2 = b.offer_price
                if (total1 > total2) return 1
                if (total2 > total1) return -1
                return 0
            })
        },
        isVisible(provider) {
            if(provider && (provider.possuiPedido == 1 && this.type == "ORDERED" || provider.possuiPedido == 0 && this.type == "NOT_ORDERED")) {
                return true;
            } else {
                return false;
            }
        },
        generate_pdf(numeroSolicitacao, seller) {
            this.$set(seller, 'is_loading_pdf', true);
            this.productService
            .generate_pdf(this.$route.params.id, numeroSolicitacao)
            .then(response => response.data)
            .then(data => {
                var file = new Blob([data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                var el = document.getElementById("download-pdf");
                el.download = "Conferencia-" + seller.loj_nomeFantasia + "-" + this.$route.params.id + ".pdf";
                el.href = fileURL;
                el.click();
                setTimeout(function(){
                    window.URL.revokeObjectURL(fileURL);
                }, 100);
                this.$set(seller, 'is_loading_pdf', false);
            })
            .catch(error => {
                this.$set(seller, 'is_loading_pdf', false);
                ErrorHandlerService.handle(error, this.$store);
            });
        }
    },
    watch: {
        'data': function(dat) {
            this.temp_sellers = dat
            this.store = this.temp_sellers[0]
            this.process_sellers()
        }
    },
    computed: {
        ...mapState([ 'user' ])
    },
    mounted() {
        this.temp_sellers = this.data
        this.store = this.temp_sellers[0]
        this.process_sellers()
    }
}
</script>

<style lang="scss" scoped>
@import "./_pre_orders_by_seller";
.empresa-titulo {
	height: 55px;
  font-family: Rubik !important;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: black !important;
}
.span-button{
	font-family: Rubik !important;
  font-size: 13px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: #a3a6b4 !important;
}
label{
  color:#4D4F5C !important
}
.bysuplier-table{
    overflow-x: auto;
}
.bysuplier-itens-container{
    border: 1px solid #E5E5E5;
    margin-bottom: 2vh;
    border-radius: 8px;
    padding: 1vh 0;
    font-size: 1vw;
    min-width: 1000px;
}
.bysuplier-title-container{
    display: flex;
    justify-content: space-between;
    padding: 0 1vw;
    align-items: center;
}
.bysuplier-title-text{
    font-weight: 400;
    font-size: 1.3em;
    color: #606060;
}
.bysuplier-title-button{
    background: rgba(23, 162, 184, 0.1);
    border: 0.6px solid #E5E5E5;
    border-radius: 9.5px;
    padding: 1.5vh 1vw;
    font-weight: 600;
    font-size: 1.3em;
    color: #5281FF;
}
.bysuplier-itens-header{
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    padding: 1vh 0;
    margin: 2vh 1vw 1.5vh 1vw;
    font-weight: 600;
    font-size: 1.19em;
    color: #605F5F;
    display: flex;
}
.bysuplier-name{flex: 1; display: flex;}
.bysuplier-date{width: 15%;}
.bysuplier-cond{width: 12%;}
.bysuplier-value{width: 12%;}
.bysuplier-min{width: 12%;}
.bysuplier-delivered{width: 12%;}
.bysuplier-icons{
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: baseline;
}
.bysuplier-icons-products{
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: baseline;

}
.bysuplier-products-icons{
    width: 16%;
    display: flex;
    justify-content: center;
    align-items: baseline;
}
.bysuplier-itens-body{
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 1em;
    color: #605F5F;
    padding: 1vh 1vw 0.8vh 1vw;
}
.bysuplier-expand-icon{
    color: var(--primary-color);
    margin-right: 20%;
    font-size: 2em;
    cursor: pointer;
}
.bysuplier-cart-icon{
    color: var(--primary-color);
    font-size: 2em;
}
.bysuplier-quantity{width: 20%;}
.bysuplier-price{width: 20%}
.bysuplier-last{width: 20%}
.bysuplier-product-row{
    display: flex;
    font-weight: 300;
    font-size: 1em;
    color: #605F5F;
    margin-top: 1.5vh;
}
.bysuplier-name-text{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 20vw;
}
.bysuplier-brand{width: 13%;}
.bysuplier-payment{width: 11%;}
.bysuplier-unity-price{width: 11%;}
.bysuplier-obs{width: 22%;}
.bysuplier-winner-row{
    display: flex;
    padding: 1vh 1vw;
    font-weight: 300;
    font-size: 1em;
    color: #605F5F;
}
.page-table-line-special{ background: #F7F7F7; }
.bysupliers-text-overflow{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 1vw;
}
.bysuplier-winner-icon{
    color: var(--primary-color);
    cursor: pointer;
}
.bysuplier-winner-icon-disabled{
    color: lightgrey;
    cursor: default;
}
@media only screen and (max-width: 1000px) {
  .bysuplier-itens-container{font-size: 14px;}
}
</style>
