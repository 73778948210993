<style lang="scss" scoped>
	@import "./negotiate-offer.modal";
</style>
<template>
	<cc-modal :modal="modal" @close="close">
			<div slot="body" class="container-fluid">
				<div class="cc-loader" :class="{ hidden : !loading }">
					<img src="/images/loader.svg"/>
					<span>Carregando</span>
				</div>
				<div class="row" :class="{ hidden : loading }">
					<div class="col-12">
						<label class="label-control">Produto</label>
						<input type="text" class="form-control" v-model="product.produtoDescricao" disabled="disabled">
					</div>
					<div class="col-6">
						<label class="label-control">Marca </label>
						<input type="text" class="form-control" v-model="product.pro_observacao" disabled="disabled">
					</div>
					<div class="col-6">
						<label class="label-control">Obs</label>
						<input type="text" class="form-control" v-model="product.observacao" :disabled="!modal.permitted">
					</div>
					<div class="col-3">
						<label class="label-control">Ultima compra </label>
						<input type="text" class="form-control" v-model="product.ultimaCompra" disabled="disabled">
					</div>
					<div class="col-3">
						<label class="label-control">Preço digitado</label>
						<input type="text" class="form-control" v-model="typing.valor" disabled="disabled">
					</div>
					<div class="col-6 new-price">
						<label></label>
						<div class="row">
							<div class="col-6">
								<label>Informe o novo preço</label>
							</div>
							<div class="col-4">
								<money v-model="new_price" v-bind="money"></money>
							</div>
						</div>
					</div>
					<div class="col-12 mt-3 align-items-center d-flex consent">
						<toggle-button
							v-model="modal.permitted"
							:color="'#ff7110'"
							:height="30"/>
						<label>Confirmo que o novo preço foi acordado com o vendedor: {{ typing.nomeVendedor }}</label>
					</div>
				</div>
			</div>
	</cc-modal>
</template>
<script>

	import QuotationService from "@/services/QuotationService";
	import ErrorHandlerService from '@/services/ErrorHandlerService';
	import Pagination from "@/components/cliente/base-components/Pagination";
	import StorageService from "@/services/local-storage.service.js";
	import { Money } from "v-money";
	

	export default {
		props: ["product", "typing", "quotation_id"],
		components:{
			Money
		},
		data() {
			return {
				new_price: 0,
				loading: false,
				service: new QuotationService(),
				modal: {
					title: "negociação",
					submit: this.update_quotation_typing,
					subtitle: 'Consiga melhores preços e condições negociando',
					icon_title: 'fa fa-handshake',
					unpermitted_message: "Aceite o termo de consentimento para continuar.",
					permitted: false
				},
				money: {
					decimal: ',',
					thousands: '.',
					prefix: 'R$ ',
					precision: 2,
					masked: false
				}
			}
		},
		methods: {
			close(with_reload = false) {
				this.loading = false;
				this.$emit('close', with_reload, this.new_price);
			},
			update_quotation_typing() {
				this.loading = true;
				this.service = new QuotationService();
				this.service.update_quotation_typing({ seller_id: this.typing.idVendedor,
													quotation_id: this.quotation_id,
													provider_id: this.typing.idFornecedor,
													product_id: this.typing.pro_id,
													unit_price: this.new_price,
                                                    observation: this.product.observacao })
				.then(response => {
					this.close(true);
				})
				.catch(data => {
					this.close(true);
				})
			}
		},
		watch: {
			"modal.permitted" : function(consent) {
				let key = "permitted_sellers";
				let val = this.typing.idVendedor;
				if(this.modal.permitted) {
					StorageService.add_to_array(key, val);
				} else {
					StorageService.remove_from_array(key, val);
				}
			}
		},
		created() {
			let key = "permitted_sellers";
			let ids = StorageService.get(key)
			this.modal.permitted = ids && ids.includes(this.typing.idVendedor);
		}
	};
</script>
