import http from "./Http";
import Service from "./Service";
import moment from "moment";


export default class QuotationService extends Service {

    export(id) {
        return http.get(`sapi/export/${id}`, {
            headers: this.getHeaders(),
            timeout: 600000
        });
    }

    export_many(ids) {
        return http.post(`sapi/exportMany`, { ids }, {
            headers: this.getHeaders(),
            timeout: 600000
        });
    }
}
