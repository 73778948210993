<template>
  <div>
    <div class="headerPopover">
      <a
        style="cursor: pointer;
    float: right;
    font-size: 20px;
    color: white;
	    margin-right: 10px;"
       @click="close()"
      >
        <i class="fas fa-times"></i>
      </a>

      <p
        style="font-size: 16px;background: #ee8c4e;
		padding-top: 5px;color: white;
    padding-left: 10px;margin-bottom: 5px;padding-right: 10px;"
      >{{product.descricaoProduto}}</p>
      <p style="margin-bottom: 5px;padding: 0px 10px;">
        Marca:
        {{salesman.pro_observacao}}
      </p>
      <p style="margin-bottom: 5px;padding: 0px 10px;">
        Fornecedor:
        {{salesman.nomeFornecedor}}
      </p>
      <p style="margin-bottom: 5px;padding: 0px 10px;">
        Emb:
        {{ salesman.embalagem}}
        <span
          style="margin-left: 10px;padding: 0px 10px;"
        >Unid. Compra: {{product.unidade_compra}}</span>
        <span style="margin-left: 10px;padding: 0px 10px;">Preço: R$ {{formatPrice(salesman.valor)}}</span>
      </p>
    </div>
    <div class="row" style="font-size: 14px;padding-right: 10px;">
      <div class="col col-md-8" style="padding-left: 25px;"></div>
      <div class="col col-md-2" style="padding-top: 3px;">Req.</div>
      <div class="col col-md-2" style="padding-top: 3px;">Ped.</div>
    </div>
        <div class="row" style="align-items: center;
    justify-content: center;">

    <i
      v-if="is_loading"
      style="font-size: 30px;
    margin: 10px 0px;
    color: #ee8c4e;"
      class="fas fa-circle-notch fa-spin"
    ></i>
     </div>
    <div
      class="row"
      style="font-size: 14px;padding-right: 10px; display: flex;align-items: center;padding-bottom: 10px;"
      v-for="item in stores"
      :key="item.codLoja"
      v-if="item.codSolicitacao"
    >
      <div
        class="col col-md-8"
        style="padding-left: 25px;
    text-align: left;
    display: flex;
    align-items: center;"
      >
        <div
          style="margin: 0px 8px;
    background: #e0e1e5;
    padding: 5px;
    border-radius: 5px;"
        >{{item.codSolicitacao}}</div>
        <div style="display: flex;
    flex-direction: column;
    align-items: flex-start;">
          {{item.descricaoLoja}}
          <p style="font-size: 12px;
    margin-bottom: 0px;">Valor: {{formatPrice(item.total)}}</p>
        </div>
      </div>
      <div class="col col-md-2" style="padding-top: 3px;">
        <input
          disabled
          class="inputBt"
          style="    width: 100%;
    max-width: 75px;
    text-align: center;"
          type="text"
          v-model="item.quantidade"
        />
      </div>
      <div class="col col-md-2" style="padding-top: 3px;">
        <input
          :disabled="item.possuiPedido > 0"
          @keyup.enter="sendQuantidade(item.quantidadeSugestao, item.codLoja)"
          class="inputBt"
          style="    width: 100%;
    max-width: 75px;
    text-align: center;"
          type="text"
          v-model="item.quantidadeSugestao"
        />
      </div>
    </div>
  </div>
</template>

<script>
import QuotationService from "@/services/QuotationService";
import ErrorHandlerService from "@/services/ErrorHandlerService";

export default {
  props: ["product", "quotation", "salesman", "closePopover", "visible"],
  data() {
    return {
      productService: new QuotationService(),
      stores: [],
      is_loading: false
    };
  },
  watch: {
    visible(val) {
      if(val){
        this.getStores();
      }
    },
  },
  methods: {
    close(){
      this.$emit("closePopover");
    },
    sendQuantidade(qtd, codLoja) {
      this.productService
        .sendQuantidade({
          numerocotacao: this.quotation,
          quantidade: qtd,
          vendedor: this.salesman.idVendedor,
          produto: this.salesman.pro_id,
          loja: codLoja
        })
        .then(response => response.data)
        .then(
          data => {},
          err => {
            if (i + 1 == providers.length) {
              this.$swal.fire(
                "Erro!",
                "Ocorreu um erro ao realizar o pedido.",
                "error"
              );
              this.is_loading = false;
            }
          }
        );
    },
    getStores() {
      this.is_loading = this.stores.length === 0;
      this.productService
        .getLojasQuantidade(
          this.salesman.pro_id,
          this.quotation,
          this.salesman.idVendedor
        )
        .then(response => response.data)
        .then(
          data => {
            
            this.stores = data.produtos;
            this.is_loading = false;
          },
          err => {
            if (i + 1 == providers.length) {
              this.$swal.fire(
                "Erro!",
                "Ocorreu um erro ao realizar o pedido.",
                "error"
              );
              this.is_loading = false;
            }
          }
        );
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  },
  created() {
  }
};
</script>
<style>
.vue-popover-theme {
  max-width: 500px !important;
  font-style: initial !important;
  font-weight: normal !important;
}
.vue-popover-theme .wrapper {
  padding-bottom: 8px;
  background: white;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
}
.vue-popover-theme .popover-inner {
  width: 500px;
  max-width: 500px !important;
  padding: 0px;
  background-color: white;
  border: 0px;
}
.vue-popover-theme .headerPopover {
  text-align: left;
  padding-bottom: 10px;
  text-align: left;
  border-bottom: 1px solid #b6b8be;
}
</style>