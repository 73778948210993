<style lang="scss" scoped>
    @import "./_products.scss";
</style>
<template>
    <div>
        <div class="cc-loader" :class="{ hidden : !is_loading }">
            <img src="/images/loader.svg" />
            <span>Carregando</span>
        </div>
		<div class="responsive-table shadow" v-for="(product, index) in products" v-show="!loj_id || product.visible" v-bind:key="product.prod_id">
			<table class="table">
      			<tbody>
        			<tr class="gray">
						<td class="product">
							<div class="toggle-winners" :class="{ hidden : !collapsed[index] }">
                                <i v-if="irregular_amount(product)" title="Verificar quantidades" class="fa fa-exclamation-circle" aria-hidden="true" style="font-size: 1.1rem;margin: 0 4px;color: red;"></i>
								<div v-if="product.flag_generico == 1" class="products-brands" @click="show_modal_brands(product)">{{product.descricaoProduto}}</div><div v-else>{{product.descricaoProduto}}</div>
							</div>
							<div class="toggle-winners" :class="{ hidden : collapsed[index] }">
                                <i v-if="irregular_amount(product)" title="Verificar quantidades" class="fa fa-exclamation-circle" aria-hidden="true" style="font-size: 1.1rem;margin: 0 4px;color: red;"></i>
								<div v-if="product.flag_generico == 1" class="products-brands" @click="show_modal_brands(product)">{{product.descricaoProduto}}</div><div v-else>{{product.descricaoProduto}}</div>
							</div>
						</td>
						<td class="cell-wrapper gen" v-if="!loj_id && product.flag_generico == 0">
							<span style="padding-top: 2px;font-size: 14px;margin-right: 5px;font-weight: 500;">Quantidade:</span>{{ product.soma_quantidades }} {{ product.pro_embalagem }}
						</td>
						<td class="cell-wrapper gen" v-if="!loj_id && product.flag_generico == 1">
							<span style="padding-top: 2px;font-size: 14px;margin-right: 5px;font-weight: 500;">Quantidade:</span>{{ product.soma_quantidades }} {{ product.unidade_compra }}
						</td>
                        <td class="cell-wrapper gen" v-if="loj_id">
							<span style="padding-top: 2px;font-size: 14px;margin-right: 5px;font-weight: 500;">Solicitado:</span>{{ product.quantidadeSolicitacao || 0 }} {{ product.flag_generico == 1 ? product.unidade_compra : product.embalagem }}
						</td>
					</tr>
					<tr class="last-buy" v-if="product.last_purchase">
						<td colspan="5" class="line">
							<div>
                                <div>Dados da ultima compra:</div>
								<div v-if="product.last_purchase.pro_observacao">
									Marca : {{ product.last_purchase.pro_observacao }}
								</div>
								<div>
									Quantidade: {{ product.last_purchase.quantidade }}
								</div>
								<div>
									Data: {{ product.last_purchase.data_geracao | formatDateOnly }}
								</div>
								<div>
									Fornecedor: {{ product.last_purchase.for_nome }}
								</div>
								<div>
									Valor: {{formatPrice( product.last_purchase.valor )}}
								</div>
							</div>
						</td>
					</tr>
      			</tbody>

    		</table>

    		<table class="table winners" :class="{ 'hidden' : collapsed[index] }">
				<thead>
					<tr>
						<th class="provider">Fornecedor</th>
						<th class="brand">Marca</th>
                        <th class="brand">Forma De Pgto</th>
						<th>QTD. EMB.</th>
						<th>Vl. unitário</th>
						<th>Observações</th>
					</tr>
      			</thead>
      			<tbody>
        			<tr v-for="(winner, idx) in product.vencedores" v-bind:key="product.prod_id + 'winner-i-' + index + '-idx-' + idx + '-' + winner.idFornecedor" >
                        <td class="provider icon">
                            <div class="checkbox-container">
                                <label class="checkmark-container">
                                    <input type="checkbox" class="input_check" v-on:click.self="toggle_winner(winner, winner.idVendedor, is_winner(winner), product)" :checked="is_winner(winner)" :disabled="winner.qtdPedido > 0">
                                    <span class="checkbox"></span>
                                </label>
                                <img class="winners-loader" :class="{ 'hidden' : !winner.is_loading }" src="/images/loader.svg"/>
                                <i :class="{ 'not-visible' : !is_winner(winner) || winner.is_loading}" class="fa fa-shopping-cart show-stores" @click="current_winner = winner;current_product=product;show_current_winner=true"></i>
                                <span :class="{ 'current-store-title' : seller_id == winner.idVendedor }">
                                    <i class="fas fa-user-plus"
                                        @click="current_winner = winner;show_outside_seller = true"
                                        v-if="winner.digitacao_outras_cotacoes"
                                        title="Novo Fornecedor Encontrado"></i>
                                    {{winner.nomeFornecedor}}
                                </span>
                            </div>
                        </td>
                        <td class="brand">
                            <i v-on:click="setPreferencial(winner)" class="far fa-star star" v-if="winner.pro_preferencial == 0 && product.flag_generico == 1" ></i>
                            <i v-on:click="setPreferencial(winner)" class="fas fa-star starred" v-if="winner.pro_preferencial == 1 && product.flag_generico == 1" ></i> {{ winner.pro_observacao }}
                        </td>
                        <td class="pgto">
                            {{(winner.forma)? winner.forma: '-'}}
                        </td>
                        <td class="quantity">
                            <input type="number"
                                ref="managed-field"
                                :class="{ 'active': winner.quantity_changed }"
                                data-visible="true"
                                v-model.number="winner.new_offer_quantity"
                                @change="() => set_quantity_changed(winner)"
                                @keydown="handle_keydown($event, winner)" />
                        </td>
                        <td class="price">
                            <money v-bind="money"
                                :class="{'winner' : is_winner(winner), 'active': winner.price_saved }"
                                class="pricing"
                                ref="managed-field"
                                data-visible="true"
                                v-model="winner.new_value"
                                @change.native="() => set_price_changed(winner)"
                                @keydown.native="handle_keydown($event, winner)" />
                        </td>
                        <td>
                            <input @keydown="handle_keydown($event, winner)"
                                class="large observation"
                                :class="{ 'active': winner.obs_changed }"
                                ref="managed-field"
                                data-visible="true"
                                type="text"
                                @change="() => set_obs_changed(winner)"
                                v-model="winner.new_observacao">
                        </td>
                    </tr>
      			</tbody>
    		</table>
  		</div>
        <cc-add-outside-request-seller :seller_id="current_winner.idVendedor"
            v-if="show_outside_seller"
            :request_id="$route.params.id"
            @close="current_winner = null;show_outside_seller=false" />
        <cc-product-sub-requests v-if="current_winner && show_current_winner"
            :salesman="current_winner"
            :loj_id="loj_id"
            :quotationId="$route.params.id"
            :product="current_product"
            @close="current_winner = null;show_current_winner=false"
            @loadProviders="loadProviders" :sellers="sellers" />

        <cc-product-brands
				id="modal"
				v-if="show_brands"
				:product="current_product"
                :product_pattern="product_pattern"
				@close="show_brands = false;current_product=null"
    		/>
    </div>
</template>
<script>

import QuotationService from "@/services/QuotationService";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import popoverStores from "@/components/popovers/stores.popover";
import ProductSubRequestsModal from "@/modals/product-sub-requests/product-sub-requests.modal";
import ProductService from '@/services/ProductService';
import AddOutsideRequestSeller from '@/modals/add-outside-request-seller/add-outside-request-seller.modal'
import RequestService from '@/services/v3/request.service'
import { elementNavigationmMixin } from '@/mixins/navigation.mixin';
import ProductBrandsModal from "@/modals/product-brands/product-brands.modal";
import { Money } from "v-money";


export default {
    mixins: [ elementNavigationmMixin ],
    components: {
        'cc-product-sub-requests': ProductSubRequestsModal,
        popoverStores,
        'cc-add-outside-request-seller': AddOutsideRequestSeller,
        'cc-product-brands' : ProductBrandsModal,
        Money
    },
    props: ["products", "wraps", "is_loading", "sellers", "loj_id", "seller_id"],
    data() {
        return {
            show_current_winner: false,
            show_outside_seller: false,
            current_winner: null,
            current_product: null,
            show_brands: false,
            product_pattern: false,
            openedPopovers: [],
            collapsed: [],
            quotationService: new QuotationService(),
            prod_svc: new ProductService(),
            svc: new RequestService(),
            delivery : {},
			money: {
				decimal: ',',
				thousands: '.',
				prefix: 'R$ ',
				precision: 2,
				masked: false
			}
        };
    },
    methods: {
        set_obs_changed(winner) {
            winner.changed = true
            winner.obs_changed = true
            this.$forceUpdate()
        },
        set_price_changed(winner) {
            winner.changed = true
            winner.price_changed = true
            this.$forceUpdate()
        },
        set_quantity_changed(winner) {
            winner.changed = true
            winner.quantity_changed = true
            this.$forceUpdate()
        },
        irregular_amount(product) {
            return product.flag_generico && product.soma_quantidades != product.quantidade_selecionada
        },
        is_winner(winner) {
            if(this.loj_id != null && winner.quantidade == 0 && winner.vencedor_quantidade == 0)return false;
            return winner.vencedor_quantidade > 0;
        },
        loadProviders(){
            this.$emit("loadProviders", false);
        },
        reload_products() {
            this.$emit("reload_products", this.$route.params.id, false);
        },
        showStores(winner){
            this.current_winner = null;
            this.current_product = null;
        },
        setPreferencial(produto) {
            produto.pro_preferencial = produto.pro_preferencial == 1 ? 0 : 1;
            this.prod_svc.pin_brand({ idProduto: produto.pro_id, preferencial: produto.pro_preferencial })
            .then(({ data }) => {
                this.$props.products.forEach(p => {
                    if (produto.produtoprincipal == p.prod_id) {
                        p.vencedores.forEach(w => {
                            if (produto.pro_id == w.pro_id) {
                                w.pro_preferencial = produto.pro_preferencial;
                            }
                        });
                    }
                });
            }).catch(function() {})
        },
        toggle_winner(winner, seller_id, is_winner, product = null) {
            this.$set(winner, "is_loading", true);
            if(this.loj_id && is_winner) {
                this.update_items_quantity(winner, this.loj_id, seller_id, () => {
                    this.$set(winner, "is_loading", false);
                    this.$set(winner, "vencedor_quantidade", 0);
                    this.$emit("loadProviders")
                });
            } else {
                this.quotationService.toggleQuotationWinner(
                    this.$route.params.id,
                    this.loj_id,
                    winner.pro_id,
                    seller_id,
                    !is_winner
                ).then(response => response.data).then(() => {
                    this.$set(winner, "is_loading", false);
                    this.$set(winner, "vencedor_quantidade", !is_winner ? 1 : 0);
                    if(this.loj_id) {
                        if(winner.vencedor_quantidade) {
                            this.current_winner = winner;
                            this.current_product=product
                        }
                        this.$emit("loadProviders")
                    }
                    this.reload_products()
                });
            }
        },
        handle_keydown($event, offer) {
            this.navigate($event, {
				ref: 'managed-field',
                callback: {
                    function: this.update_offer,
                    params: offer
                }
			})
        },
        update_offer(offer) {
            let data = {
                quantity: offer.new_offer_quantity,
                price: offer.new_value,
                obs: offer.new_observacao
            }

            if(offer.new_value == offer.valor &&
               offer.new_offer_quantity == offer.offer_quantity &&
               offer.new_observacao == offer.observacao) {
                return Promise.resolve()
            }

            return this.svc.update_offer(this.$route.params.id, offer.idVendedor, offer.pro_id, data).then(() => {
                const notification = {
                    type: 'success',
                    message: 'Dados salvos com sucesso!'
                }
                this.$store.dispatch('notification/add', notification)
                offer.valor = offer.new_value
                offer.offer_quantity = offer.new_offer_quantity
                offer.observacao = offer.new_observacao
                if(offer.price_changed) {
                    offer.price_saved = true
                }
            })
        },
        update_items_quantity(winner, loj_id, seller_id, callback) {
            this.quotationService.sendQuantidade({
                numerocotacao: "" + this.$route.params.id,
                quantidade: "0",
                vendedor: seller_id,
                produto: winner.pro_id,
                loja: loj_id
            })
            .then(response => response.data)
            .then(callback);
        },
        getStore(winner, loj_id, seller_id, callback) {
            var quotationService = new QuotationService();
            quotationService.getLojasQuantidade(
                winner.pro_id,
                this.$route.params.id,
                seller_id,
                loj_id
            ).then(response => response.data)
            .then(callback).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        toggle_collapse(index) {
            this.$set(this.collapsed, index, !this.collapsed[index]);
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(".", ",");
            return "R$ " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        getFormaPagamento(sellers,winner){
            let delivery = [];
            if(sellers.fornecedores.length > 0){
                delivery = sellers.fornecedores
                .filter(f => {

                    return f.idVendedor == winner.idVendedor
                })
            }
            return delivery && delivery[0] ? delivery[0].entrega.forma : '-';
        },
        show_modal_brands(product){
            this.prod_svc.get_product_brands_admin(product.pro_id)
			.then((data)=>{
                this.build_products_brands(product,data.data.produtos)
				this.show_brands = true;
			})
        },
        build_products_brands(product, brands){
            let product_brand = product;
            product_brand.id = product.pro_id;
            product_brand.name = product.descricaoProduto;
            product_brand.derivatives = [];
            brands.forEach(p => {
                product_brand.derivatives.push(this.buil_product_derivative(p));
            });
            this.current_product = product_brand;
        },
        buil_product_derivative(derivative){
            return {id: derivative.pro_id,brand: derivative.pro_observacao};
        }
  }
};
</script>
