<style lang="scss" scoped>
    @import '@/sass/commons/_variables';
    .table {
        b {
            font-weight: bolder;
            color: $primary-color;
        }
    }
</style>
<template>
    <cc-modal :modal="modal" @close="close">
        <div slot="body">
            <cc-loader v-show="loading"/>
            <div class="row justify-content-center" v-show="!loading">
                <table class="table">
                    <tbody v-if="seller && delivery">
                        <tr>
                            <td><b>Vendedor</b></td>
                            <td>{{ seller.usu_nome }}</td>
                            <td><b>Telefone</b></td>
                            <td>{{ seller.usu_telefone || '-' }}</td>
                        </tr>
                        <tr>
                            <td><b>CNPJ</b></td>
                            <td>{{ seller.provider.for_cnpj }}</td>
                            <td><b>Contato vendedor</b></td>
                            <td>{{ seller.provider.for_contato }}</td>
                        </tr>
                        <tr>
                            <td><b>Razão social</b></td>
                            <td>{{ seller.provider.for_razaoSocial }}</td>
                            <td><b>Fornecedor</b></td>
                            <td>{{ seller.provider.for_nome }}</td>
                        </tr>
                        <tr>
                            <td><b>Nome fantasia</b></td>
                            <td>{{ seller.provider.for_nomeFantasia }}</td>
                            <td><b>Plano de pagamento</b></td>
                            <td>{{ delivery.forma }}</td>
                        </tr>
                        <tr>
                            <td><b>Email</b></td>
                            <td>{{ seller.usu_email }}</td>
                            <td><b>Data de entrega</b></td>
                            <td>{{ delivery.data_entrega | formatDate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </cc-modal>
</template>

<script>

import SellerService from "@/services/v2/sellers.service";
import ErrorHandlerService from '@/services/ErrorHandlerService';

export default {
    props: ["seller_id", "request_id"],
    data() {
        return {
            service: new SellerService(),
            loading: true,
            seller: null,
            delivery: null,
            modal: {
                permitted: true,
                icon_title: 'fas fa-user-plus',
                subtitle: 'Novo fornecedor encontrado',
                submit_text: 'Atualizar',
                cancel_text: 'Voltar',
                style: {
                    width: "70%"
                }
            }
        };
    },
    methods: {
        close(reload = false) {
            this.$emit("close", reload);
            if(reload) {
                this.$emit("reload", false);
            }
        },
        load_seller() {
            return this.service.find(this.seller_id).then(response => response.data)
            .then(data => data.data).then((data) => {
                this.seller = data
                this.modal.title = this.seller.provider.for_nome
                this.loading = false
            }).catch(e => {
                this.loading = false
                ErrorHandlerService.handle(e, this.$store)
            })
        },
        load_seller_delivery() {
            return this.service.find_delivery(this.request_id, this.seller_id)
            .then(data => data.data).then((data) => {
                console.log(data)
                this.delivery = data
            }).catch(e => {
                ErrorHandlerService.handle(e, this.$store)
            })
        }
    },
    created() {
        this.load_seller().then(() => this.loading = false)
        this.load_seller_delivery()
    }
};
</script>
